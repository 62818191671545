import React from 'react'

import WhoIsIntro from '../components/whoisintro';
import WhoIsContent from '../components/whoiscontent';
import FaqBlock from '../components/whoisfaq';
import FooterBanner from '../components/footerbanner';
import ContactIntro from '../components/contactintro';
import ContactForm from '../components/contactform';
import ContactCalendar from '../components/contactcalendar';
import JerryDoesIntro from '../components/jerrydoesintro';
import Carousel from '../components/carousel';
import JerryDoesBody from '../components/jerrydoesbody';
import WhatPeopleSayIntro from '../components/whatpeoplesayintro';
import WhatPeopleSayBody from '../components/whatpeoplesaybody';
import NewsAndEventsIntro from '../components/newsandeventsintro';
import HomeBody from '../components/homebody';
import NewsAndEventsCards from '../components/newsandeventscards';
import BasicPage from '../components/basic-page';

const RenderBlock = (param, el, i) => {
  let block = {
    'WordPressAcf_who_is_intro': (el, i) => (<WhoIsIntro {...el} key={i} />),
    'WordPressAcf_who_is_content': (el, i) => (<WhoIsContent {...el} key={i} />),
    'WordPressAcf_who_is_faq': (el, i) => (<FaqBlock {...el} key={i} />),
    'WordPressAcf_footer_banner': (el, i) => (<FooterBanner {...el} key={i} />),
    'WordPressAcf_contact_intro': (el, i) => (<ContactIntro {...el} key={i} />),
    'WordPressAcf_contact_form': (el, i) => (<ContactForm {...el} key={i} />),
    'WordPressAcf_contact_calendar': (el, i) => (<ContactCalendar {...el} key={i} />),
    'WordPressAcf_jerry_does_intro': (el, i) => (<JerryDoesIntro {...el} key={i} />),
    'WordPressAcf_carousel': (el, i) => (<Carousel {...el} key={i} />),
    'WordPressAcf_jerry_does_body': (el, i) => (<JerryDoesBody {...el} key={i} />),
    'WordPressAcf_what_people_say_intro': (el, i) => (<WhatPeopleSayIntro {...el} key={i} />),
    'WordPressAcf_what_people_say_body': (el, i) => (<WhatPeopleSayBody {...el} key={i} />),
    'WordPressAcf_news_and_events_intro': (el, i) => (<NewsAndEventsIntro {...el} key={i} />),
    'WordPressAcf_homebody': (el, i) => (<HomeBody {...el} key={i} />),
    'WordPressAcf_news_and_events_cards': (el, i) => (<NewsAndEventsCards {...el} key={i} />),
    'WordPressAcf_basic_page': (el, i) => (<BasicPage {...el} key={i} />),
  }[param]

  if (!block) return

  return block(el, i)
}

export default RenderBlock
