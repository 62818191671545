import React, { Component } from 'react'
import { graphql } from 'gatsby'
import he from 'he'

import SEO from '../components/seo'

import RenderBlock from '../utils/render-block'

class PageTemplate extends Component {
  render() {
    let page = this.props.data.wordpressPage
    return (
      <>
        <SEO title={he.decode(page.yoast_meta.yoast_wpseo_title)} bodyClass={page.slug} description={page.yoast_meta.yoast_wpseo_metadesc} />
        { page.acf.content_blocks_page && page.acf.content_blocks_page.map((el, i) => {
          return RenderBlock(el.__typename, el, i)
        }) }
      </>
    )
  }
}

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPage(id: {eq: $id}) {
      title
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      acf {
        content_blocks_page {
          __typename
          ... on WordPressAcf_who_is_intro {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            subheading
            content
          }
          ... on WordPressAcf_who_is_content {
            title
            subheading
            content
            text_right
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
          }
          ... on WordPressAcf_who_is_faq {
            faq_item {
              question
              answer
            }
          }
          ... on WordPressAcf_footer_banner {
            title
            heading
            content
            link_url
          }
          ... on WordPressAcf_contact_intro {
            heading
            title
            info
          }
          ... on WordPressAcf_contact_form {
            title
            list {
              name
            }
          }
          ... on WordPressAcf_contact_calendar {
            title
            content
            calendar
          }

          ... on WordPressAcf_jerry_does_intro {
            title
            subheading
          }

          ... on WordPressAcf_carousel {
            item {
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              text
            }
          }
          ... on WordPressAcf_jerry_does_body {
              item {
                title
                content
            	}
            }

          ... on WordPressAcf_what_people_say_intro {
            title
            subheading
          }
          ... on WordPressAcf_what_people_say_body {
            item {
              title
              content
              image {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
              info
              video
            }
          }
          ... on WordPressAcf_article_body {
            datetime
            title
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            subheading
            content
          }
          ... on WordPressAcf_homebody {
            image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            mobile_image {
              localFile {
                childImageSharp {
                  original {
                    src
                  }
                }
              }
            }
            title
            slides {
              info
              link
              linktext
            }
          }
          ... on WordPressAcf_news_and_events_intro {
            title
            subheading
          }
          ... on WordPressAcf_basic_page {
            title
            content
          }
        }
      }
    }
  }
`

export default PageTemplate
