import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class WhoIsIntro extends Component {

  render() {
    const { image, content, title, subheading } = this.props
    return (
      <>
        <section className="whoisintro">
          <Fade bottom distance='40px' cascade>
            <div className="whoisintro__inner container">
              <div className="whoisintro__left">
                <picture>
                  { image && image.localFile && <img src={image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
                </picture>
              </div>
              <div className="whoisintro__right">
                <h1>{ title }</h1>
                <p className="subheading">{ subheading }</p>
                <div className="content" dangerouslySetInnerHTML={{ __html: content }} />
              </div>
            </div>
          </Fade>
        </section>
      </>
    )
  }
}



export default WhoIsIntro;
