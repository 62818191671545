import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

import Play from '../assets/images/play.svg'

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal active" : "modal";
  return (
    <div className={showHideClassName}>
      <section className="modal__wrapper">
        {children}
      </section>
      <button className="modal__close" onClick={handleClose} />
    </div>
  )
}

class WhatPeopleSayBody extends Component {

  state = {
    activeSlide: 0,
    show: false,
    video: ''
  }

  showModal = (video) => {
    this.setState({ show: true, video: video }, function(){
      let src = document.querySelector('.video-wrapper iframe').getAttribute('src')
      src += '&autoplay=1'
      document.querySelector('.video-wrapper iframe').setAttribute('src', src)
    });
  };

  hideModal = () => {
    this.setState({ show: false, video: '' });
  };

  render() {
    let data = this.props
    return (
      <>
        <section className="whatpeoplesaybody">
          <div className="whatpeoplesaybody__inner container">
            <Fade bottom distance='40px' cascade>
            { data && data.item && data.item.map((el, i) => (
              <div className="whatpeoplesaybody__item" key={i}>
                <div className="whatpeoplesaybody__top">
                  <div className="whatpeoplesaybody__topleft">
                    <h1>{el.title}</h1>
                    <p className="content">{ el.content }</p>
                  </div>
                  <div className="whatpeoplesaybody__topright">
                    <picture onClick={() => this.showModal(el.video)}>
                      { el.image && <img src={el.image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
                      <img className='play' src={Play} alt='Jerry Y - Testimonial' />
                    </picture>
                  </div>
                </div>
                <div className="whatpeoplesaybody__bottom">
                  <p className="contentbottom">{el.info}</p>
                </div>
              </div>
            )) }
            </Fade>
          </div>
        </section>
        <Modal show={this.state.show} handleClose={this.hideModal}>
          <div className="video-wrapper" dangerouslySetInnerHTML={{ __html: this.state.show && this.state.video }} />
        </Modal>
      </>
    )
  }
}



export default WhatPeopleSayBody;
