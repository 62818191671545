import React, { Component } from 'react'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'
import Select from 'react-select';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const options = [
  { value: 'property_development', label: 'Need assistance with a Property Development' },
  { value: 'project_manager', label: 'Require Project Management of a property' },
  { value: 'coaching_client', label: 'Property Coaching & Mentoring' },
  { value: 'joint_venture_partner', label: 'Interested in a joint venture partnership' },
  { value: 'potential_seller', label: 'Potential Seller' },
  { value: 'landlord', label: 'Landlord' },
];

class Form extends Component {

  state = {
    name: '',
    email: '',
    phone: '',
    enquiry: '',
    describes_you: '',
    describes_valid: true,
    selectedOption: null,
  }

  handleSelectChange = selectedOption => {
    this.setState({ selectedOption })
    let describes = ''
    selectedOption.forEach(function(option, index, array){
      if (index === 0) {
        describes += option.label
      } else {
        describes += ', '+option.label
      }
    })
    this.setState({ 
      describes_you: describes, 
      describes_valid: true
    })
  };

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()

    if ( this.state.describes_you === '' ) {
      this.setState({ describes_valid: false })
      return false
    }

    if (!this.refs.form.checkValidity()) return false

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": this.refs.form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => {
        this.setState({result: 'success'})
        navigate('/thank-you')
      })
      .catch(error => this.setState({result: 'fail', msg: error}))

  }

  render() {
    let props = {
      ref: 'form',
      name: 'contact',
      onSubmit: this.handleSubmit,
      action: '/thank-you',
      'data-netlify': 'true',
      'data-netlify-honeypot': 'bot-field',
    }

    return (
      <form {...props}>
        <input type="hidden" name="form-name" value="contact" />
        <div className="form__row">
          <div className="form__col">
            <input type="text" name="name" id="name" onChange={this.handleChange} required placeholder="Name" />
          </div>
          <div className="form__col">
            <input type="email" name="email" id="email" onChange={this.handleChange} placeholder="Email" required />
          </div>
          <div className="form__col">
            <input type="phone" name="phone" id="phone" onChange={this.handleChange} placeholder="Phone" required />
          </div>
          <div className={this.state.describes_valid ? 'form__col' : 'form__col invalid'}>
            <Select
              onChange={this.handleSelectChange}
              options={options}
              isMulti={true}
              placeholder={'Which of the following best describes you?'}
              className={'multi-select__wrapper'}
              classNamePrefix={'multi-select'}
            />
            <input className='form__hidden' type='text' name='describes_you' onChange={this.handleSelectChange} value={this.state.describes_you} />
          </div>
          <div className="form__col">
            <textarea type="enquiry" rows={6} name="enquiry" id="enquiry" onChange={this.handleChange} placeholder="Message" required />
          </div>
        </div>
        <div className="form__row form__row--submit">
          <button className="contactbtn" type="submit">Submit</button>
        </div>
      </form>
    )
  }
}

class ContactForm extends Component {

  render() {
    let data = this.props

    return (
      <>
        <section className="contactform">
          <div className="contactform__inner container">
            <Fade bottom distance='40px' cascade>
              <div className="contactform__left">
                <p>{data.title}</p>
                <ul>
                  { data.list.map((el, i) => (
                    <li className="key" key={i} dangerouslySetInnerHTML={{__html: el.name}}/>
                  )) }
                </ul>
              </div>
              <div className="contactform__right">
                <Form />
              </div>
            </Fade>
          </div>
        </section>
      </>
    )
  }
}

export default ContactForm
