import React, { Component } from 'react'
//import { Link } from 'gatsby'
import Slider from 'react-slick'
import Fade from 'react-reveal/Fade'
import {StaticQuery, graphql} from 'gatsby'

import left from '../assets/images/arrow-back.svg'
import right from '../assets/images/arrow.svg'
import Insta from '../assets/images/insta.svg'
import FB from '../assets/images/fb.svg'
import Youtube from '../assets/images/youtube.svg'
import LinkenIn from '../assets/images/linkedin.svg'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class AlternateFooter extends Component {

  state = {
    activeSlide: 0,
    slider: null,
    thumbs: null
  }

  componentDidMount() {
    this.setState({
      slider: this.slider,
      thumbs: this.thumbs,
    });
  }

  render() {
    const { items, data } = this.props

    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      asNavFor: this.state.thumbs,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }

    return (
      <>
        <section className="alternatefooter">
          <div className="alternatefooter__inner">
            <Fade bottom distance='40px'>
              <div className="alternatefooter__left">
                <div className="alternatefooter__nav--left">
                  <p className="copyright">© 2019 JERRY Y PROPERTY MENTOR</p>
                </div>
                <ul className="alternatefooter__nav--right">
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.instagram}><img src={Insta} alt="Instagram" /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.facebook}><img src={FB} alt="FaceBook" /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.youtube}><img src={Youtube} alt="YouTube"  /></a></li>
                  <li><a target='_blank' rel='nofollow noopener noreferrer' href={data.options.options.linkedin}><img src={LinkenIn} alt="LinkedIn" /></a></li>
                </ul>
              </div>
            </Fade>
            <Fade bottom distance='40px'>
              <div className="alternatefooter__right">
                <Slider className="alternatefooter__slider" ref={slider => (this.slider = slider)} {...settings}>
                  { items && items.map((el, i) => (
                    <div className="alternatefooter__items" alternatefooter__items={i} key={i}>
                      <p className="info">
                        {el.info}
                      </p>
                      <a className="link" href={el.link}>
                        <p className="linktext">{el.linktext}</p>
                      </a>
                    </div>
                  ))}
                </Slider>
              </div>
            </Fade>
          </div>
        </section>
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        options: wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            instagram
            facebook
            youtube
            linkedin
          }
        }
      }
    `}
    render={data => <AlternateFooter data={data} {...props} />}
  />
)
