import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class JerryDoesBody extends Component {


  render() {
    let data = this.props
    return (
      <>
        <section className="jerrydoesbody">
          <Fade bottom distance='40px' cascade>
            { data.item.map((el, i) => (
              <div className="jerrydoesbody__item" key={i}>
                <div className="jerrydoesbody__inner">
                  <h3> {el.title}</h3>
                  <p className="content"> { el.content }</p>
                </div>
              </div>
            )) }
          </Fade>
        </section>
      </>
    )
  }
}



export default JerryDoesBody
