import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class JerryDoesIntro extends Component {

  render() {
    let data = this.props
    return (
        <>
          <section className="jerrydoesintro">
            <div className="jerrydoesintro__inner container">

            <Fade bottom distance='40px' cascade>
              <div className="jerrydoesintro__innertop">
                <h1 className="title"> {data.title} </h1>
              </div>


              <div className="jerrydoesintro__innerbottom">
                <p className="title"> {data.subheading} </p>
              </div>
            </Fade>

          </div>
        </section>
      </>
    )
  }
}

export default JerryDoesIntro
