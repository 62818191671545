import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ContactCalendar extends Component {

  render() {

    let data = this.props

    return (
      <>
        <section className="contactcalendar">
          <div className="contactcalendar__inner container">
            <Fade bottom distance='40px' cascade>
              <div className="contactcalendar__info">
                <p className="title"> {data.title} </p>
                <p className="content">{data.content}</p>
              </div>
              <div className="contactcalendar__calendar" dangerouslySetInnerHTML={{ __html: data.calendar }} />
            </Fade>
          </div>
        </section>
      </>
    )
  }
}

export default ContactCalendar
