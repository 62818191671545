import React, { Component } from "react"
import Slider from 'react-slick'
import Fade from 'react-reveal/Fade'

import left from '../assets/images/arrow-back-black.svg'
import right from '../assets/images/arrow-black.svg'

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={right}
      alt='next gallery item'
      onClick={onClick}
    />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      style={{ ...style }}
      src={left}
      alt='last gallery item'
      onClick={onClick}
    />
  );
}

class Carousel extends Component {

  state = {
    activeSlide: 0,
  }
  constructor(props) {
    super(props);
    this.state = {
      slider: null,
      thumbs: null
    };
  }

  componentDidMount() {
    this.setState({
      slider: this.slider,
      thumbs: this.thumbs,
    });
  }

  render() {
    let data = this.props
    const settings = {
      infinite: true,
      speed: 600,
      fade:true,
      autoplay: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      cssEase: 'ease',
      pauseOnHover: false,
      focusOnSelect: false,
      asNavFor: this.state.thumbs,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    }

    return (
      <>
        <section className="carousel">
          <div className="container">
            <Slider className="carousel__slider" ref={slider => (this.slider = slider)} {...settings}>
              {data.item.map((el,i) => (
                <div className="carousel__item" carousel__item={i} key={i}>
                  <Fade bottom distance='40px'>
                    <h2 className="title">Featured Projects</h2>
                    <div className="carousel__left">
                      <figure>
                        { el.image && <img src={el.image.localFile.childImageSharp.original.src} alt="Jerry-Y"/>}
                      </figure>
                    </div>
                    <div className="carousel__right">
                      <div className="carousel__content">
                        <h4>{el.text}</h4>
                      </div>
                    </div>
                  </Fade>
                </div>
              ))}
            </Slider>
          </div>
        </section>
      </>
    )
  }
}

export default Carousel

