import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class NewsAndEventsIntro extends Component {

  render() {
    let data = this.props
    return (
      <>
        <section className="newsandeventsintro">
          <div className="newsandeventsintro__inner container">
            <Fade bottom distance='40px' cascade>

              <div className="newsandeventsintro__innertop">
                <h1 className="title"> {data.title}</h1>
              </div>


              <div className="newsandeventsintro__innerbottom">
                <p className="subheading"> {data.subheading} </p>
              </div>
            </Fade>

          </div>
        </section>
      </>
    )
  }
}



export default NewsAndEventsIntro
