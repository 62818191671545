import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class FaqBlock extends Component {

  static defaultProps = {
    title: '',
    questions: [],
  }

  state = {
    itemActive: {}
  }


  collapseSection(element) {
    let sectionHeight = element.scrollHeight

    let elementTransition = element.style.transition
    element.style.transition = ''

    requestAnimationFrame(function() {
      element.style.height = sectionHeight + 'px'
      element.style.transition = elementTransition

      requestAnimationFrame(function() {
        element.style.height = 0 + 'px'
      })
    })

    element.setAttribute('data-collapsed', 'true')
  }

  expandSection(element) {
    let sectionHeight = element.scrollHeight
    element.style.height = sectionHeight + 'px'
    element.setAttribute('data-collapsed', 'false')
  }

  _toggleItem = (itemId) => {
    let current = this.state.itemActive[itemId]
    let { itemActive } = this.state
    itemActive[itemId] = !current
    let element = this.refs[`q_${itemId}`]
    current ? this.collapseSection(element) : this.expandSection(element)
    this.setState({ itemActive })
  }

  _renderArrow() {
    return (
      <span className='arrow'>
        <img src={require('../assets/images/plus.svg')} alt='faq toggle' />
      </span>
    )
  }

  render() {
    let data = this.props
    let { itemActive } = this.state
    return (
      <section className='faq-block'>
        <div className='faq-block__inner container'>
          <Fade bottom distance='40px'>
            <h4>FAQ's</h4>
            <div className='faq-block__list'>
              {data.faq_item && data.faq_item.map((el, i) => (
                <div className={`faq-block__item ${itemActive[i] ? 'active' : ''}`} key={i}>
                  <p className='faq-block__title' onClick={e => this._toggleItem(i)}>
                    <span className='title'>{el.question}</span>
                    <span className="arrow"></span>
                  </p>
                  <div className='faq-block__item__content' ref={`q_${i}`} dangerouslySetInnerHTML={{ __html: el.answer }} />
                </div>
              ))}
            </div>
          </Fade>
        </div>
      </section>
    )
  }
}

export default FaqBlock
