import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
//import Fade from 'react-reveal/Fade'

import ArticleCard from '../cards/article'

class NewsAndEventsCards extends Component {

  render() {
    let posts = this.props.data.allWordpressPost.edges
    let odd  = posts.filter((v, i) => i % 2 === 1)
    let even = posts.filter((v, i) => i % 2 === 0)

    return (
      <section className="newsandeventscards">
        <div className="newsandeventscards__inner container">
          <div>{ even.map((el, i) => <ArticleCard key={i} {...el.node} />) }</div>
          <div>{ odd.map((el, i) => <ArticleCard key={i} {...el.node} />) }</div>
        </div>
      </section>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allWordpressPost {
          edges {
            node {
              title
              slug
              date(formatString: "DD/MM/YY")
              acf {
                subtitle
                excerpt
                content
                image {
                  localFile {
                    childImageSharp {
                      original {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => <NewsAndEventsCards data={data} {...props} />}
  />
)
