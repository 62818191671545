import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class WhoIsContent extends Component {

  render() {
    let data = this.props
    return (
      <>
        <section className="whoiscontent">
          <div className={`whoiscontent__inner container ${data.text_right ? 'whoiscontent__inner--flip' : ''}`}>
            <Fade bottom distance='40px' cascade>
              <div className="whoiscontent__left">
                { data.title && <h2>{data.title}</h2> }
                { data.subheading && <p className="subheading">{data.subheading}</p> }
                <div className="content" dangerouslySetInnerHTML={{ __html: data.content }} />
              </div>
              <div className="whoiscontent__right">
                <picture>
                  { data.image && <img src={data.image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
                </picture>
              </div>
            </Fade>
          </div>
        </section>
      </>
    )
  }
}



export default WhoIsContent;
