import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class WhatPeopleSayIntro extends Component {

  render() {
    let data = this.props;
    return (
      <>
        <section className="whatpeoplesayintro">
          <div className="whatpeoplesayintro__inner container">

            <Fade bottom distance='40px' cascade>

              <div className="whatpeoplesayintro__innertop">
                <h1 className="title"> {data.title} </h1>
              </div>

              <div className="whatpeoplesayintro__innerbottom">
                <p className="subheading"> {data.subheading} </p>
              </div>
            </Fade>

          </div>
        </section>
      </>
    )
  }
}

export default WhatPeopleSayIntro
