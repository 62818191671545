import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class ContactIntro extends Component {

  render() {
    let data = this.props
    return (
      <>
        <section className="contactintro">
          <Fade bottom distance='40px' cascade>
            <div className="contactintro__inner container">
              <div className="contactintro__left">
                <h1> {data.heading} </h1>
              </div>
              <div className="contactintro__right">
                <p className="title"> {data.title} </p>
                <p className="info" dangerouslySetInnerHTML={{ __html: data.info }} />
              </div>
            </div>
          </Fade>
        </section>
      </>
    )
  }
}

export default ContactIntro
