import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'

class BasicPage extends Component {

  render() {
    let data = this.props
    return (
      <>
        <section className="basic">
          <Fade bottom distance='40px' cascade>
            <div className="basic__inner container">
              <div className="basic__content">
                <h1>{data.title}</h1>
                <div className="info" dangerouslySetInnerHTML={{ __html: data.content }} />
              </div>
            </div>
          </Fade>
        </section>
      </>
    )
  }
}

export default BasicPage