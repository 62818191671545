import React, { Component } from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

import AlternateFooter from './alternatefooter'

class HomeBody extends Component {

  render() {
    let data = this.props
    return (
      <>
        <section className="homebody">
          <div className="homebody__inner">
            <Fade bottom distance='40px' cascade>
              <div className="homebody__top">
                <h1>{ data.title }</h1>
                <Link to='/book-appointment'>Book a FREE Strategy Session with Jerry</Link>
              </div>
            </Fade>
            <div className="homebody__content">
              <AlternateFooter items={data.slides} />
            </div>
          </div>
          <picture className="homebody__background">
            { data.image && <img className="desktop" src={data.image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
            { data.mobile_image && <img className="mobile" src={data.mobile_image.localFile.childImageSharp.original.src} alt="Jerry-Y"/> }
          </picture>
        </section>
      </>
    )
  }
}



export default HomeBody
