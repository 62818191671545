import React, { Component } from 'react';
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade'

class FooterBanner extends Component {

  render() {
    let { title, heading, content, link_url } = this.props

    if (link_url) content = (<Link to={link_url}>{ content }</Link>)

    return (
        <>
          <section className="footerbanner">
            <div className="footerbanner__inner container">
              <Fade bottom distance='40px' cascade>
                <div className="footerbanner__prefooter">
                  <p className="title">{ title }</p>
                  <h3 className="heading">{ heading }</h3>
                  <p className="content">{ content }</p>
                </div>
              </Fade>
          </div>
        </section>
      </>
    )
  }
}

export default FooterBanner
